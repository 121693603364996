import { createApp } from 'vue'
import axios from './util/axios';
import FormatDate from './util/utils';
import Cookies from 'js-cookie'
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
// import zhCn from "element-plus/lib/locale/lang/zh-cn"
import 'element-plus/theme-chalk/index.css'
import toast from './toast' // message 提示消息插件
import router from "./router/index"
import 'lib-flexible'
import './util/flexible.js'
import App from './App.vue'
import store from "./store/index" // 引入

import DDConfig from './util/ddapi'


import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-dark.css'


// import  VueSocketIO  from  'vue-socket.io'
// import SocketIO from 'socket.io-client'

const app = createApp(App)


// import  VueSocketIO  from  'vue-3-socket.io'
// import socketio from 'socket.io-client'
// app.use(new  VueSocketIO({    
//     debug: true,
//     connection: socketio('ws://192.168.2.122:7272')
// }))


// import VueSocketIO from 'vue-3-socket.io'
// import io from 'socket.io-client';
// const socketio = new VueSocketIO({
//     debug: true,
//     connection: io('ws://192.168.2.122:7272',{ transports : ['websocket'] })
// });
// socketio.on('connect', function(){
// 	//socket.emit('login', uid);
// 	alert('链接成功');
// });




app.use(ElementPlus,{size:'small',zIndex: 3000,locale: zhCn})

app.use(store)
app.use(router)

app.directive('highlight',function (el) {
  let blocks = el.querySelectorAll('pre code');
  blocks.forEach((block)=>{
    hljs.highlightBlock(block)
  })
})


// app.use(socketio)



import * as ELIcons from '@element-plus/icons-vue'
for(let iconName in ELIcons){
 app.component(iconName,ELIcons[iconName])
}

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$toast = toast;
app.config.globalProperties.$formatTime = FormatDate
app.config.globalProperties.DDConfig = DDConfig

app.mount('#app')
// app.config.globalProperties.$socket = socket;
