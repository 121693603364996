<template>
	<div class="content1 flexs">
		<el-dialog v-model="show1" top="50Px" :show-close="false" :close-on-click-modal="false">
			<div class="model">
				<div class="m_cons">
					<div class="lists">
						<div class="tit">1.您所在的城市？</div>
						<div class="list">
							<div class="flexs1">
								<el-select v-model="pro" class="m-2" placeholder="Select" size="large">
								    <el-option
								      v-for="item in options"
								      :key="item.value"
								      :label="item.label"
								      :value="item.value"
								    />
								  </el-select>
								  <el-select v-model="city" class="m-2" placeholder="Select" size="large">
								      <el-option
								        v-for="item in options"
								        :key="item.value"
								        :label="item.label"
								        :value="item.value"
								      />
								    </el-select>
							</div>
						</div>
						<div class="tit">2.选择您的身份？（单选）</div>
						<div class="list list2 flexs1">
							<div class="btn" v-for="(item, index) in 7" :key="index" :class="status == index?'actives':''" @click="toSelect(index)">在校生</div>
						</div>
						<div class="tit">3.感兴趣的内容？（可多选）</div>
						<div class="list list2 flexs1">
							<div class="btn" v-for="(item, index) in list" :key="index" :class="item.check?'actives':''" @click="checkBox(item)">{{item.name}}</div>
						</div>
						<div class="btns">立即领取新人礼包</div>
					</div>
				</div>
			</div>
			<div class="flexs">
				<img class="close_img" src="../../src/assets/image/close2.png" @click="show1 = false"/>
			</div>
			
		</el-dialog>
		
	</div>
</template>

<script>
	export default {
		name: 'newExclusive',
		data() {
			return {
				show1: false,
				status: 1,
				phone:'',
				code:'',
				sunmax: 60,
				stus: true, //倒计时标识
				text: '获取验证码',
				options:[
					{
					    value: 'Option1',
					    label: 'Option1',
					  },
					  {
					    value: 'Option2',
					    label: 'Option2',
					  },
					  {
					    value: 'Option3',
					    label: 'Option3',
					  },
					  {
					    value: 'Option4',
					    label: 'Option4',
					  },
					  {
					    value: 'Option5',
					    label: 'Option5',
					  }
				],
				pro:'',
				city:'',
				status: 0,
				list:[
					{
						name:" java基础",
						id: 1,
						check: false
					},
					{
						name:" java框架",
						id: 2,
						check: false
					},
					{
						name:" java项目",
						id: 3,
						check: false
					},
					{
						name:" 毕业设计",
						id: 4,
						check: false
					},
					{
						name:"  程序员面试",
						id: 5,
						check: false
					},
					{
						name:" 同行交流",
						id: 6,
						check: false
					}
				],
				idList:[]
				
			}
		},
		created() {
			
		},
		methods: {
			toselect(num){
				this.status = num
			},
			//获取验证码
			getcode(num) {
				if (/^1[345789]\d{9}$/.test(this.phone)) {
					this.stus = false
					this.text = this.sunmax;
					let timeInt = setInterval(() => {
						this.text--;
						if (this.text <= 0) {
							this.stus = true;
							this.text = '获取验证码';
							clearInterval(timeInt);
						}
					}, 1000);
					this.getcodes();
				} else {
					this.$toast({text:'手机号格式不符，请重新输入'});
				}
			},
			toSelect(num) {
				this.status = num
			},
			checkBox(item){
				item.check = !item.check
				if (this.idList.indexOf(item.id) === -1) {
					this.idList.push(item.id)
					// this.interest.push(item.name)
				} else {
					this.idList.splice(this.idList.indexOf(item.id), 1)
					// this.interest.splice(this.interest.indexOf(item.name), 1)
				}
				console.log(this.idList)
			},
		}
	}
</script>

<style scoped lang="scss">
	.content1{
		.model{
			width: 590Px;
			height: 815Px;
			background: url(../../src/assets/image/xrzx.png);
			background-size: 100% 100%;
			border-radius: 10Px;
			margin: 0 auto;
			position: relative;
			right: 18Px;
			.m_cons{
				width: 560Px;
				height: 530Px;
				background: #FFFFFF;
				border-radius: 10Px;
				position: absolute;
				bottom: 17Px;
				left: 15Px;
				.lists{
					padding: 20Px 13Px;
					.tit{
						padding: 0 0 20Px 20Px;
					}
					.list{
						padding: 0 0 29Px 24Px;
						flex-wrap: wrap;
						.el-select{
							width: 240Px;
							height: 40Px;
							margin: 0 10Px;
						}
						.btn{
							height: 40Px;
							border-radius: 4Px;
							padding: 0 20Px;
							line-height: 40Px;
							border: 1Px solid #E8E8E8;
							margin: 0 10Px 20Px 10Px;
							color: #444444;
							cursor: pointer;
						}
						.actives{
							border: 1Px solid #2C97FF;
							color: #2C97FF;
							background: #EEF7FF;
						}
					}
					.list2{
						padding-bottom: 9Px;
					}
				}
			}
		}
		
		
		
		
		.btns{
			width: 400Px;
			margin: 0 auto;
			height: 48Px;
			line-height: 48Px;

		}
		
		.close_img{
			width: 30Px;
			height: 30Px;
			margin-top: 17Px;
			cursor: pointer;
		}
	}
	
</style>
<style>
	.content1 .el-dialog{
		width: 590Px;
		background: none;
		box-shadow: none;
	}
	
</style>