<template>
  <div class="xtx-message" v-if="visible">{{text}}</div>
</template>
<script>
import { ref, onMounted } from 'vue'
export default {
  name: 'XtxMessage',
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      // warn 警告  error 错误  success 成功
      default: 'warn'
    }
  },
  setup () {
    // 定义一个对象，包含三种情况的样式，对象key就是类型字符串
    const visible = ref(false);
    onMounted(()=>{
      visible.value = true
    })
    return { visible }
  }
}
</script>
<style scoped lang="scss">

.xtx-message {
  position: fixed;
  left: 50%;
  top:50%;
  background: rgba(0,0,0,.5);
  padding: 10px;
  border-radius: 5px;
  transform: translate(-50%,-50%);
  color:#fff;
  z-index: 9999999;
  font-size: 20px;
}
.down-enter-from {
  transform: translate3d(0,-75px,0);
  opacity: 0;
}
.down-enter-active {transition: all 0.5s;}
.down-enter-to { transform: none; opacity: 1;}
</style>