import { createStore } from 'vuex'
import moduleUser from './modules/user'
const store = createStore({
  state() {
    return {
      title: 'Hello, Vuex',
      count: 0,
	  courseInfo: JSON.parse(sessionStorage.getItem("COURSE_INFO")) || {},
	  graduationInfo: JSON.parse(sessionStorage.getItem("GRADUATION_INFO")) || {},
	  searchShow: true,
	  login_show: false,
	  token: sessionStorage.getItem("TOKEN"),
	  userShow: sessionStorage.getItem("USER_SHOW") || "meaasge",
	  aboutStus: sessionStorage.getItem("ABOUT_STUS") || 1,
	  closeStus: sessionStorage.getItem("CLOSE_STUS") || false,
	  videoInfo: JSON.parse(sessionStorage.getItem("VIDEO_INFO")) || {},
	  articleId: JSON.parse(sessionStorage.getItem("ARTICLE_ID")) || {},
	  interviewsStus: JSON.parse(sessionStorage.getItem("INTE_STUS")) || '',
	  circleStus: JSON.parse(sessionStorage.getItem("CIRCLE_STUS")) || '',
    }
  },
  getters: {
    // courseInfo: state => state.courseInfo,
  },
  mutations: {
    SET_COURSE_INFO(state, data) {
      state.courseInfo = data
      sessionStorage.setItem("COURSE_INFO", JSON.stringify(data))
    },
	SET_GRADUATION_INFO(state, data) {
	  state.graduationInfo = data
	  sessionStorage.setItem("GRADUATION_INFO", JSON.stringify(data))
	},
	SET_VIDEO_INFO(state, data) {
	  state.videoInfo = data
	  sessionStorage.setItem("VIDEO_INFO", JSON.stringify(data))
	},
	SET_ARTICLE_ID(state, data) {
	  state.articleId = data
	  sessionStorage.setItem("ARTICLE_ID", JSON.stringify(data))
	},
	SET_INTE_STUS(state, data) {
	  state.interviewsStus = data
	  sessionStorage.setItem("INTE_STUS", JSON.stringify(data))
	},
	SET_CIRCLE_STUS(state, data) {
	  state.circleStus = data
	  sessionStorage.setItem("CIRCLE_STUS", JSON.stringify(data))
	},
	SEARCH_DATA(state, data) {
	  state.searchShow = data
	},
	SET_LOGIN_SHOW(state, data) {
	  state.login_show = data
	},
	SET_TOKEN(state, data) {
	  state.token = data
	  sessionStorage.setItem("TOKEN", data)
	},
	SET_USER_SHOW(state, data) {
	  state.userShow = data
	  sessionStorage.setItem("USER_SHOW", data)
	},
	SET_ABOUT_STUS(state, data) {
	  state.aboutStus = data
	  sessionStorage.setItem("ABOUT_STUS", data)
	},
	SET_CLOSE_STUS(state, data) {
	  state.closeStus = data
	  sessionStorage.setItem("CLOSE_STUS", data)
	},
	increment(state) {
	  state.count ++
	}
  },
  modules:{
	  user:moduleUser
  }
})

export default store
