<template>
	<div class="content2 flexs">
		<el-dialog v-model="show1" top="100px" :show-close="false" :close-on-click-modal="false">
			<div class="model">
				<div class="m_header flexs3">
					<img class="close_img" src="../../src/assets/image/close.png" @click="hideModal()" />
				</div>
				<div class="tits flexs1">
					<div class="xian"></div>
					<div class="text-bold">礼包资料</div>
				</div>
				<div class="list flexs2">
					<img src="../../src/assets/image/login_bg.png"/>
					<div class="right">
						<div class="name line2">史上最全的IntelliJ IDEA开发工具王—开发工开发工具王你get了么？</div>
						<div class="text-gray2">最好的java开发工具之一</div>
					</div>
				</div>
				<div class="tits flexs1">
					<div class="xian"></div>
					<div class="text-bold">添加老师，领取更多资料</div>
				</div>
				<div class="flexs">
					<img class="teacher_img" src="../../src/assets/image/teacher.png"/>
				</div>
				<div class="btns" :class="phone != '' && code !=''?'active_btn':''"> 登录</div>
				
			</div>
		
		
		</el-dialog>
		<el-dialog v-model="show2" width="420px" top="150px" :show-close="false" :close-on-click-modal="false">
			<div class="model model2 padd20">
				<div class="m_header flexs3">
					<img class="close_img" src="../../src/assets/image/close.png" @click="hideModal()" />
				</div>
				<div class="flexs">
					<img class="kefu_img" src="../../src/assets/image/kefu.png"/>
				</div>
				<template v-if="status == 1">
					<div class="flexs bot15">
						<div>联系您的专属老师</div>
						<div class="text-orange">13434564657(手机号）</div>
					</div>
					<div class="flexs bot15">获取更多学习资料和服务！</div>
				</template>
				<template v-else>
					<div class="flexs bot15">
						<div>添加您的专属老师</div>
						<div class="text-orange">sh13434564657(微信）</div>
					</div>
					<div class="flexs bot15">获取更多学习资料和服务！</div>
				</template>
			</div>
		
		
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'giftbagData',
		data() {
			return {
				show1: false,
				show2: false,
				status: 1,
				phone:'',
				code:'',
				sunmax: 60,
				stus: true, //倒计时标识
				text: '获取验证码',
				
			}
		},
		created() {
			
		},
		methods: {
			toselect(num){
				this.status = num
			},
			//获取验证码
			getcode(num) {
				if (/^1[345789]\d{9}$/.test(this.phone)) {
					this.stus = false
					this.text = this.sunmax;
					let timeInt = setInterval(() => {
						this.text--;
						if (this.text <= 0) {
							this.stus = true;
							this.text = '获取验证码';
							clearInterval(timeInt);
						}
					}, 1000);
					this.getcodes();
				} else {
					this.$toast({text:'手机号格式不符，请重新输入'});
				}
			},
			getcodes() {
				var obj = {
					mobile: this.phone
				}
				this.$axios.get("/admin/sendCode",obj).then((res) => {
					this.$toast({text:res.msg});
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.content{
		.model{
			width: 590px;
			background: #FFFFFF;
			border-radius: 10px;
			margin: 0 auto;
			padding-bottom: 40px;
		}
		
		
		.model2{
			width: 420Px;
			.m_header {
				width: 100%;
				height: 60Px;
				.close_img{
					width: 20Px;
					height: 20Px;
					margin-right: 20Px;
				}
			}
		}
		.tits{
			font-size: 18px;
			padding-left: 20px;
			.xian{
				border-radius: 2px;
				background: #FE4400;
				width: 4px;
				height: 20px;
				margin-right: 5px;
			}
		}
		.list{
			margin: 0 20px 30px 20px;
			padding: 25px 0 30px 0;
			border-bottom: 1px solid #ECECEC;
			img{
				width: 170px;
				height: 94px;
			}
			.right{
				width: 360px;
				height: 85px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				font-size: 16px;
			}
		}
		.teacher_img{
			width: 160px;
			height: 160px;
			margin: 45px 0 35px 0;
		}
		.kefu_img{
			width: 150Px;
			height: 150Px;
			margin: 30Px 0 40Px 0;
		}
		
		
		.btns{
			margin: 0 auto;
			width: 400px;
			height: 48px;
			line-height: 48px;
		}
		.bot15{
			margin-bottom: 15Px;
			font-size: 18Px;
			color: #222222;
		}
	
	}
	
</style>
<style>
	.content2 .el-dialog{
		width: 590px;
		background: none;
		box-shadow: none;
	}
</style>