const moduleUser = {
	state: {
		userInfo:  JSON.parse(sessionStorage.getItem("USER_INFO")) || {},
		msgCode: sessionStorage.getItem("MSG_CODE") || 4000
	},

	mutations: {
		SET_USER_INFO(state, data) {
		  state.userInfo = data
		  sessionStorage.setItem("USER_INFO", JSON.stringify(data))
		},
		SET_MSG_CODE(state, data) {
		  state.msgCode = data
		  sessionStorage.setItem("MSG_CODE", JSON.stringify(data))
		},
	},

	actions: {
		// 登录

	}
}
export default moduleUser
