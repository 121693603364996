<template>
	<div class="login_content flexs">
		<el-dialog v-model="$store.state.login_show" width="450Px" :show-close="false" :close-on-click-modal="false">
			<div class="model">
				<div class="m_header flexs3">
					<img class="close_img" v-if="closeStus && !$store.state.closeStus" src="../../src/assets/image/close.png" @click="loginHideModal(1)" />
				</div>
				<div class="flexs size20">欢迎登录动力节点旗下狮课网学习平台</div>
				<div class="flexs lists">
					<!-- <div class="flexs type_list" :class="status == 1?'type_list2':''" @click="toselect(1)">
						<div>手机号登录</div>
						<div class="xian"></div>
					</div> -->
					<div class="flexs type_list" :class="status == 2?'type_list2':''" @click="toselect(2)">
						<div>微信登录</div>
						<div class="xian"></div>
					</div>
				</div>
				<template v-if="status == 1">
					<div class="lists">
						<input class="ipt1" v-model="phone" placeholder="请输入手机号" />
					</div>
					<div class="lists flexs2">
						<input class="ipt1 ipt2" v-model="code" placeholder="请输入验证码" />
						<div class="btn flexs" v-if="stus" @click="getcode">{{ text }}</div>
						<div class="btn flexs" v-else>{{ text }}S</div>
					</div>
					<div class="btns" v-if="phone == '' || code ==''"> 登录</div>
					<div class="btns active_btn" v-else @click="submits"> 登录</div>
				</template>
				<template v-if="status == 2">
					<div class="erweima">
						<!-- <img :src="wx_img"/> -->
						<div id="imgb"> <canvas width="200" height="200" id="canvas" ref="codes"></canvas></div>
						<div class="text-grey">微信扫一扫，快速登录</div>
					</div>
				</template>
				<div class="flexs m_bot">
					<div abort="text-gray">登录即同意狮课网</div>
					<div class="text-blue flexs1">
						<div class="cursors" @click="xieyi">《用户协议》</div>
						<div class="cursors" @click="zhengce">《隐私政策》</div>
					</div>
				</div>
			</div>


		</el-dialog>
		<el-dialog v-model="show2" width="450Px" top="250Px" :show-close="false" :close-on-click-modal="false">
			<div class="model padd20">
				<div class="m_header flexs3">
					<img class="close_img" src="../../src/assets/image/close.png" @click="bindHideModal(2)" />
				</div>
				<div class="size20 tits">绑定手机号</div>
				<div class="lists">
					<input class="ipt1" v-model="phone" placeholder="请输入手机号" />
				</div>
				<div class="lists flexs2">
					<input class="ipt1 ipt2" v-model="code" placeholder="请输入验证码" />
					<div class="btn flexs" v-if="stus" @click="getcode">{{ text }}</div>
					<div class="btn flexs" v-else>{{ text }}S</div>
				</div>
				<div class="btns" v-if="phone == '' || code ==''">确认绑定</div>
				<div class="btns active_btn" v-else @click="submits2"> 确认绑定</div>
			</div>


		</el-dialog>
	</div>
</template>

<script>
	import html2canvas from "html2canvas";
	import useClipboard from 'vue-clipboard3'
	const {
		toClipboard
	} = useClipboard();
	import {
		ref
	} from "vue";
	import qrcode from "qrcode";
	export default {
		name: 'loginModal',
		props: { //接收父组件的数据
			closeStus: {
				type: Boolean,
				default: true
			},
		},
		data() {
			return {
				show1: false,
				show2: false,
				status: 2,
				phone: '',
				code: '',
				sunmax: 60,
				stus: true, //倒计时标识
				text: '获取验证码',
				typeLogin: 1,
				wx_img: '',
				scene_id: '',
				times:'',
				is_scan: 0,
				bind_identity: 0,
				bind_mobile: 0,
				token:''
			}
		},
		created() {
			
			// if(localStorage.getItem('login_show')){
			// 	this.show1 = localStorage.getItem('login_show');
			// }
			// if(localStorage.getItem('typeLogin')){
			// 	this.status = localStorage.getItem('typeLogin');
			// }
			console.log(this.status)
		},

		watch: {
			"$store.state.login_show": {
				handler(val,oldval) {
					console.log(val)
					if(val){
						this.getEwmCode();
					} else {
						clearInterval(this.times)
					}
				},
				immediate: true,
				deep: true
			},
			
		},
		methods: {
			getEwmCode() {
				this.$axios.get("/wechat/getWechatCode").then((res) => {
					if (res.msgCode == 0) {
						this.wx_img = res.result.url
						this.scene_id = res.result.scene_id
						
						this.$nextTick(() => {
							qrcode.toCanvas(this.$refs.codes, this.wx_img, function(error) {
								console.log(error);
							});
						})
						this.times = setInterval(res => {
							this.getIsScan()
						},2000)
					}
				})
			},
			submits() {
				var obj = {
					mobile: this.phone,
					code: this.code
				}
				this.$axios.get("/user/loginByMobile", obj).then((res) => {
					if (res.msgCode == 0) {
						this.loginHideModal(1)
					}
					this.$toast({
						text: res.msg
					});
				})
			},
			submits2() {
				var obj = {
					mobile: this.phone,
					code: this.code,
					token: this.token
				}
				this.$axios.post("/user/bindMobile", obj).then((res) => {
					if (res.msgCode == 0) {
						this.bindHideModal()
						localStorage.setItem('token', this.token);
						this.getUserData()
					}
					this.$toast({
						text: res.msg
					});
				})
			},
			getUserData() {
				
				this.$axios.get("/user/getUserInfo").then((res) => {
					this.$store.commit("SET_MSG_CODE",res.msgCode)
					console.log(this.$store.state.user.msgCode)
					console.log(999999999999)
					if(res.msgCode == 0){
						this.$store.commit("SET_USER_INFO",res.result.data)
					}
				})
			},
			toselect(num) {
				this.status = num
				localStorage.setItem('typeLogin', num);
				
				if(num == 2){
					this.$nextTick(() => {
						qrcode.toCanvas(this.$refs.codes, this.wx_img, function(error) {
							console.log(error);
						});
					})
					this.times = setInterval(res => {
						this.getIsScan()
					},2000)
				} else {
					clearInterval(this.times)
				}



			},
			getIsScan(){
				var obj = {
					scene_id: this.scene_id
				}
				this.$axios.get("/wechat/isScan", obj).then((res) => {
					if(res.msgCode == 0){
						if(res.result.is_scan == 1){
							clearInterval(this.times)
							this.token = res.result.token
							this.bind_identity = res.result.bind_identity
							this.bind_mobile = res.result.bind_mobile
							localStorage.setItem('share_id', res.result.share_id);
							this.loginHideModal()
							if(this.bind_mobile == 0){
								this.show2 = true
							} else {
								localStorage.setItem('token', this.token);
								this.getUserData()
							}
						}
					} else {
						clearInterval(this.times)
					}
				})
			},
			//获取验证码
			getcode(num) {
				if (/^1[3456789]\d{9}$/.test(this.phone)) {
					this.stus = false
					this.text = this.sunmax;
					let timeInt = setInterval(() => {
						this.text--;
						if (this.text <= 0) {
							this.stus = true;
							this.text = '获取验证码';
							clearInterval(timeInt);
						}
					}, 1000);
					this.getcodes();
				} else {
					this.$toast({
						text: '手机号格式不符，请重新输入'
					});
				}
			},
			getcodes() {
				var obj = {
					mobile: this.phone
				}
				this.$axios.get("/user/sendCode", obj).then((res) => {
					this.$toast({
						text: res.msg
					});
				})
			},
			loginHideModal(num) {
				this.phone = ''
				this.code = ''
				this.$store.commit("SET_LOGIN_SHOW",false)
				clearInterval(this.times)
			},
			bindHideModal(num) {
				this.phone = ''
				this.code = ''
				this.show2 = false
			},
			xieyi() {
				let pathInfo = this.$router.resolve({
					path: 'xieyi',
					query: params
				})
				window.open(pathInfo.href, '_blank');
				// this.$router.push({path:'xieyi'})
			},
			zhengce() {
				let pathInfo = this.$router.resolve({
					path: 'zhengce',
					query: params
				})
				window.open(pathInfo.href, '_blank');
				// this.$router.push({path:'zhengce'})
			},
		}
	}
</script>

<style scoped lang="scss">
	.login_content {
		.m_header {
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 60Px;

			.close_img {
				width: 20Px;
				height: 20Px;
				margin-right: 20Px;
				cursor: pointer;
			}
		}

		.size20 {
			font-size: 20Px;
			color: #444444;
			margin-bottom: 35Px;
			letter-spacing: 2Px;
		}

		.lists {
			width: 360Px;
			margin: 0 auto 30Px auto;

			.type_list {
				flex-direction: column;
				color: #888888;
				font-size: 18Px;
				margin: 0 25Px 0 6Px;
				cursor: pointer;

				.xian {
					width: 24Px;
					height: 4Px;
					background: #FFFFFF;
					margin-top: 9Px;
				}
			}

			.type_list2 {
				color: #FE4400;
				font-weight: bold;

				.xian {
					background: #FE4400;
				}
			}

			.ipt1 {
				width: 100%;
				height: 48Px;
				border-radius: 24Px;
				background: #F2F2F2;
				padding: 0 20Px;
			}

			.ipt2 {
				width: 220Px;
			}

			.btn {
				width: 120Px;
				height: 48Px;
				border-radius: 24Px;
				background: #F2F2F2;
				cursor: pointer;
			}

		}

		.tits {
			width: 360Px;
			padding-left: 6Px;
			margin: 0 auto 35Px auto;
		}

		.btns {
			margin: 0 auto;
			opacity: 0.7;
		}

		.m_bot {
			padding: 30Px 0 20Px 0;
		}

		.erweima {
			text-align: center;

			
		}
		#imgb,#canvas{
			width: 200Px;
			height: 170Px;
			margin: 0 auto;
		}

		.padd20 {
			padding-bottom: 20Px;
		}
	}
</style>
