const routes = [{
		path: '/',
		name: 'home',
		component: () => import("../views/home"),
		meta: {
			keepAlive: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/livePage',
		name: 'livePage',
		component: () => import("../views/livePage"),
		meta: {
			title: '直播课·IT攻城狮的在线课堂',
			content: {
				keywords: '关键字1，关键字2', 
				description: '描述内容描述内容描述内容描述内容描述内容描述内容描述内容'
			},
			keepAlive2: true
		}
	},
	{
		path: '/liveDetails',
		name: 'liveDetails',
		component: () => import("../views/lives/liveDetails"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '直播·IT攻城狮的在线课堂',
			content: {
				keywords: '关键字1，关键字2',
				description: '描述内容描述内容描述内容描述内容描述内容描述内容描述内容'
			}
		}
	},
	{
		path: '/goodsClass',
		name: 'goodsClass',
		component: () => import("../views/goodsClass"),
		meta: {
			keepAlive2: true,
			footStatus1: true,
			title: '线上课·IT攻城狮的在线课堂',
			content: {
				keywords: '关键字1，关键字2',
				description: '描述内容描述内容描述内容描述内容描述内容描述内容描述内容'
			}
		}
	},
	{
		path: '/interviews',
		name: 'interviews',
		component: () => import("../views/interviews"),
		meta: {
		 keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/graduations',
		name: 'graduations',
		component: () => import("../views/graduations"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/graduationsDetail',
		name: 'graduationsDetail',
		component: () => import("../views/graduation/graduationsDetail"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/circles',
		name: 'circles',
		component: () => import("../views/circles"),
		meta: {
			keepAlive2: true,
			footStatus1: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/articleDetail',
		name: 'articleDetail',
		component: () => import("../views/circles/articleDetail"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/user',
		name: 'user',
		component: () => import("../views/user"),
		meta: {
			keepAlive4: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/classDetail',
		name: 'classDetail',
		component: () => import("../views/goodClass/classDetail"),
		meta: {
		 keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/faceDetail',
		name: 'faceDetail',
		component: () => import("../views/goodClass/faceDetail"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/seeVideo',
		name: 'seeVideo',
		component: () => import("../views/goodClass/seeVideo"),
		meta: {
			keepAlive3: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/lookVideo',
		name: 'lookVideo',
		component: () => import("../views/graduation/lookVideo"),
		meta: {
			keepAlive3: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/headlineVideo',
		name: 'headlineVideo',
		component: () => import("../views/circles/headlineVideo"),
		meta: {
			keepAlive3: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},

	{
		path: '/payCenter',
		name: 'payCenter',
		component: () => import("../views/goodClass/payCenter"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/paySuccess',
		name: 'paySuccess',
		component: () => import("../views/goodClass/paySuccess"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/liabilitys',
		name: 'liabilitys',
		component: () => import("../views/topics/liabilitys"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/copyrights',
		name: 'copyrights',
		component: () => import("../views/topics/copyrights"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/theTest1',
		name: 'theTest1',
		component: () => import("../views/topics/theTest1"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/answers',
		name: 'answers',
		component: () => import("../views/topics/answers"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/musttopicDetail',
		name: 'musttopicDetail',
		component: () => import("../views/topics/musttopicDetail"),
		meta: {
			keepAlive2: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/readMustTopic',
		name: 'readMustTopic',
		component: () => import("../views/topics/readMustTopic"),
		meta: {
			keepAlive2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/xieyi',
		name: 'xieyi',
		component: () => import("../views/publics/xieyi"),
		meta: {
			keepAlive4: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/zhengce',
		name: 'zhengce',
		component: () => import("../views/publics/zhengce"),
		meta: {
			keepAlive4: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/aboutUs',
		name: 'aboutUs',
		component: () => import("../views/publics/aboutUs"),
		meta: {
			keepAlive5: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/searchPage',
		name: 'searchPage',
		component: () => import("../views/publics/searchPage"),
		meta: {
			keepAlive4: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/measurement',
		name: 'measurement',
		component: () => import("../views/publics/measurement"),
		meta: {
			keepAlive4: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/results',
		name: 'results',
		component: () => import("../views/publics/results"),
		meta: {
			keepAlive4: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/javaTest1',
		name: 'javaTest1',
		component: () => import("../views/publics/javaTest1"),
		meta: {
			keepAlive4: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
	{
		path: '/javaTest2',
		name: 'javaTest2',
		component: () => import("../views/publics/javaTest2"),
		meta: {
			keepAlive4: true,
			footStatus2: true,
			title: '狮课网·IT攻城狮的在线课堂',
			content: {
				keywords: '',
				description: ''
			}
		}
	},
]
export default routes;
