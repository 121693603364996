<template>
	<div id="app" @click="hideAll">
		<!-- 其他页 -->
		<div v-if="$route.meta.isLogin" class="page2">
			<router-view></router-view>
		</div>
		<div v-else>
			<div v-if="$route.meta.keepAlive" class="page">
				<el-container>
					<el-header>
						<nav-header :headShow1="headShow1" @changeHeadShow1="changeHeadShow1"></nav-header>
					</el-header>
							
					<el-main>
						<router-view></router-view>
					</el-main>
					<el-footer>
						<foot-page></foot-page>
					</el-footer>
				</el-container>
			</div>
			<div v-if="$route.meta.keepAlive2 || $route.meta.keepAlive4" class="page2">
				<el-container>
					<el-header>
						<nav-header2 v-if="$route.meta.keepAlive2" :headShow1="headShow1" @changeHeadShow1="changeHeadShow1"></nav-header2>
						<nav-header3 v-if="$route.meta.keepAlive4" :headShow1="headShow1" @changeHeadShow1="changeHeadShow1"></nav-header3>
					</el-header>
							
					<el-main>
						<router-view></router-view>
					</el-main>
					<el-footer v-if="$route.meta.footStatus1 || $route.meta.footStatus2">
						<foot-page2 v-if="$route.meta.footStatus1"></foot-page2>
						<foot-page3 v-if="$route.meta.footStatus2"></foot-page3>
					</el-footer>
				</el-container>
			</div>
			<div v-if="$route.meta.keepAlive5" class="page4">
				<el-container>
					<el-header>
						<nav-header4 :headShow1="headShow1" @changeHeadShow1="changeHeadShow1"></nav-header4>
					</el-header>
							
					<el-main>
						<router-view></router-view>
					</el-main>
					<el-footer>
						<foot-page3></foot-page3>
					</el-footer>
				</el-container>
			</div>
			<div v-if="$route.meta.keepAlive3" class="page3">
				<el-container>
					<el-main>
						<router-view></router-view>
					</el-main>
				</el-container>
			</div>
		</div>
	</div>
</template>

<script>
	import NavHeader from './components/NavHeader.vue';
	import FootPage from './components/FootPage.vue';
	import NavHeader2 from './components/NavHeader2.vue';
	import NavHeader3 from './components/NavHeader3.vue';
	import NavHeader4 from './components/NavHeader4.vue';
	import PageLeft from './components/PageLeft.vue';
	import FootPage2 from './components/FootPage2.vue';
	import FootPage3 from './components/FootPage3.vue';
	import axios from './util/axios';
	export default {
		name: 'App',
		components: {
			navHeader: NavHeader,
			navHeader2: NavHeader2,
			navHeader3: NavHeader3,
			navHeader4: NavHeader4,
			FootPage: FootPage,
			FootPage2: FootPage2,
			FootPage3: FootPage3,
			pageLeft: PageLeft
		},
		data() {
			return {
				headShow1: false
			}
		},
		mounted() {
			
		},
		created() {
			// if(localStorage.getItem('admin_name')){
			// 	axios.get("/admin/checkLogin").then((res) => {
			// 		if(res.msgCode == 4000){
			// 			this.$router.push('/login')
			// 		}
			// 	})
			// } else {
			// 	this.$router.push('/login')
			// }
		},
		methods:{
			changeHeadShow1(e){
				this.headShow1 = e
			},
			hideAll(){
				this.headShow1 = false
			}
		}
	}
</script>

<style>
	*{
		padding: 0;
		margin: 0;
	}
	.el-dialog{
		/* border-radius: 10px; */
	}
	/* html::-webkit-scrollbar {
		width: 0px;
	}
	
	html::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 2px;
	}
	
	html::-webkit-scrollbar-thumb {
		background: #ffffff;
		border-radius: 10px;
	} */
	.el-main{
		min-width: 1200px;
		/* border: 1px solid red; */
		/* overflow-x: hidden; */
	}
	.el-main {
	    overflow:visible !important;
	}
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #222222;
		font-size: 14px;
		/* border: 1px solid red; */
		min-width: 1200px;
		/* font-family: "宋体"; */
	}
	body{
		margin: 0;
	}
	input{
	}
	.el-header{
		width: 100%;
		padding: 0;
		background: #FFFFFF;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 999;
	}
	
	.page .el-main{
		margin-top: 150px;
		width: 100%;
		padding: 0;
	}
	.page2 .el-main{
		margin-top: 70px;
		width: 100%;
		padding: 0;
		background: #F7F7F7;
		min-height: 70vh;
	}
	.page3 .el-main{
		padding: 0;
		min-height: 70vh;
	}
	.page4 .el-main{
		padding: 0;
		min-height: 70vh;
		margin-top: 100px;
		background: #F7F7F7;
	}
	.el-footer{
		width: 100%;
		padding: 0;
	}
	.flexs {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.flexs1 {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}

	.flexs2 {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.flexs3 {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	.size12{
		font-size: 12px;
	}
	.size14{
		font-size: 14px;
	}
	.size16{
		font-size: 16px;
	}
	.size18{
		font-size: 18px;
	}
	.size20{
		font-size: 20px;
	}
	
	.btns {
		width: 360px;
		height: 48px;
		border-radius: 24px;
		background: #FE4400;
		font-size: 16px;
		color: #FFFFFF;
		text-align: center;
		line-height: 48px;
		cursor: pointer;
	}
	.cursors{
		cursor: pointer;
	}
	.text-grey{
		color: #666666;
	}
	.text-gray{
		color: #999999;
	}
	.text-gray2{
		color: #888888;
	}
	.text-red{
		color: #FF0000;
	}
	.text-orange{
		color: #FE4400;
	}
	.text-blue{
		color: #2C97FF;
	}
	.text-green{
		color: #1FC186;
	}
	.text-bold{
		font-weight: bold;
	}
	.el-main{
	}
	.active_btn {
		opacity: 1 !important;
	}
	table{
		font-size: 14px;
	}
	th{
		color: #666666;
	}
	.notes{
		font-size: 14px;
		color: #999999;
		margin-top: 5px;
	}
	th{
		font-weight: bold;
	}
	.noClick{
		opacity: 0.7;
	}
	.disableBtn{
		opacity: 0.6;
	}
	.line2 {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
	}
	
	.line1 {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.el-dialog {
	  display: flex;
	  flex-direction: column;
	  margin: 0 !important;
	  position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
	}
	img{
		vertical-align:top;
	}
</style>
