export default function FormatDate(value) {
      let result = parseInt(value);
 
      let y =
        Math.floor(result / 86400) < 10
          ? "0" + Math.floor(result / 86400)
          : Math.floor(result / 86400);
 
      let h =
        Math.floor((result / 3600) % 24) < 10
          ? "0" + Math.floor((result / 3600) % 24)
          : Math.floor((result / 3600) % 24);
 
      let m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
 
      let s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);
 
      let res = "";
      if (y !== "00") res += `${y}天`;
 
      if (h !== "00") res += `${h}时`;
 
      if (m !== "00") res += `${m}分`;
 
      res += `${s}秒`;
 
      return res;
    }

