<template>
	<div class="foot_content flexs">
		<div class="cons">
			<div class="flexs2 cons1">
				<div class="flexs1 list1">
					<div @click="aboutUs(1)">关于我们</div>
					<div @click="aboutUs(2)">联系我们</div>
					<div @click="aboutUs(3)">讲师招募</div>
					<div @click="gouser">意见反馈</div>
					<div @click="xieyi">用户协议</div>
					<div @click="zhengce">隐私政策</div>
				</div>
				<div class="flexs3 list2">
					<el-tooltip class="box-item" effect="dark" content="使用Ctrl+D可将网站添加到书签" placement="top-start">
						<div class="flexs">
							<div class="img1">
							</div>
							<div>收藏网站</div>
						</div>
					</el-tooltip>
					<div class="flexs" @click="show1 = true">
						<div class="img2">
						</div>
						<div>下载APP</div>
					</div>
					<div class="flexs" @click="show2 = true">
						<div class="img3">
						</div>
						<div>官方公众号</div>
					</div>
				</div>
			</div>
			<div class="size12 text-gray flexs1">
				北京动力节点教育科技有限公司 &nbsp;Copyright© 2022 skcto.com 京ICP备09027468号-1
				<img class="ga_img" src="../assets/publics/ga.png" />
				京公网安备 11011502006023号
			</div>
			<!-- <div class="text-grey top30">友情链接： </div>
			<div class="list3 text-grey flexs1">
				<div v-for="(item, index) in 20" :key="index">动力节点</div>
			</div> -->
		</div>
		<el-dialog v-model="show1" width="360Px" top="200Px" :show-close="false" :close-on-click-modal="false">
			<div class="model">
				<div class="m_header flexs3">
					<img class="close_img" src="../assets/image/close.png" @click="show1 = false" />
				</div>
				<div class="m_cons">
					<img class="ewm_img" src="../assets/publics/xzapp.png" />
					<div class="text-gray">
						扫描二维码
						下载狮课APP
					</div>
				</div>

			</div>
		</el-dialog>
		<el-dialog v-model="show2" width="360Px" top="200Px" :show-close="false" :close-on-click-modal="false">
			<div class="model">
				<div class="m_header flexs3">
					<img class="close_img" src="../assets/image/close.png" @click="show2 = false" />
				</div>
				<div class="m_cons">
					<img class="ewm_img" src="../assets/publics/skgzh.jpg" />
					<div class="text-gray">
						扫描二维码
						关注狮课微信公众号
					</div>
				</div>

			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: 'FootPage',
		data() {
			return {
				show1: false,
				show2: false
			}
		},
		created() {

		},
		methods: {
			xieyi() {
				this.$router.push({
					path: 'xieyi'
				})
			},
			zhengce() {
				this.$router.push({
					path: 'zhengce'
				})
			},
			aboutUs(num){
				this.$store.commit("SET_ABOUT_STUS", num)
				this.$router.push({path:'aboutUs'})
			},
			gouser() {
				if (!localStorage.getItem('token') || this.$store.state.user.msgCode == 4000) {
					this.$store.commit("SET_LOGIN_SHOW", true)
					this.$store.commit("SET_CLOSE_STUS",false)
					return
				}
				this.$store.commit("SET_USER_SHOW","feedBack")
				this.$router.push({
					path: 'user'
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.foot_content {
		background: #FFFFFF;
		font-size: 14Px;
		width: 100%;

		.cons {
			min-width: 1200Px;
			max-width: 1200Px;
			padding-bottom: 50Px;

			.cons1 {
				padding: 30Px 0 20Px 0;
				color: #666666;

				.list1 {
					div {
						margin-right: 50Px;
						cursor: pointer;
					}
				}

				.list2 {
					font-size: 12Px;
					color: #888888;

					.flexs {
						flex-direction: column;
						margin-left: 50Px;

						.img1 {
							width: 36Px;
							height: 36Px;
							margin-bottom: 9Px;
							background: url(../assets/image/sc1.png);
							background-size: 100% 100%;
							cursor: pointer;
						}

						.img1:hover {
							background: url(../assets/image/sc2.png);
							background-size: 100% 100%;
						}

						.img2 {
							width: 36Px;
							height: 36Px;
							margin-bottom: 9Px;
							background: url(../assets/image/xz1.png);
							background-size: 100% 100%;
							cursor: pointer;
						}

						.img2:hover {
							background: url(../assets/image/xz2.png);
							background-size: 100% 100%;
						}

						.img3 {
							width: 36Px;
							height: 36Px;
							margin-bottom: 9Px;
							background: url(../assets/image/gzh.png);
							background-size: 100% 100%;
							cursor: pointer;
						}

						.img3:hover {
							background: url(../assets/image/gzh2.png);
							background-size: 100% 100%;
						}
					}
				}
			}

			.top30 {
				margin-top: 30Px;
			}

			.list3 {
				margin-top: 26Px;
				flex-wrap: wrap;

				div {
					margin-right: 40Px;
					margin-bottom: 30Px;
				}
			}
		}

		.size12 {
			font-size: 12Px;

			.ga_img {
				width: 16Px;
				height: 16Px;
				margin: 0 2Px 0 10Px;
			}
		}

		.model {
			background: #FFFFFF;
			border-radius: 10Px;
			margin: 0 auto;

			.m_header {
				width: 100%;
				height: 60Px;
				position: absolute;
				top: 0;
				left: 0;

				.close_img {
					width: 20Px;
					height: 20Px;
					margin-right: 20Px;
					cursor: pointer;
				}
			}

			.m_cons {
				text-align: center;

				.ewm_img {
					width: 212Px;
					height: 212Px;
					margin-bottom: 20Px;
				}
			}

		}
	}
</style>
