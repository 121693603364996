import { createRouter, createWebHistory } from "vue-router"
import routes  from "./routes"
var router=createRouter({
    history:createWebHistory(),
    routes
})
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title
  if (to.meta.content) {
          let head = document.getElementsByTagName('head');
          let meta = document.createElement('meta');
          // document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
          document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
          meta.cOntent= to.meta.content;
          head[0].appendChild(meta)
      }
  next()
})
export default router


