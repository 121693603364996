export default {
  ddShowMenu: function(title, img) {
    dd.ready(function() {
      dd.biz.navigation.setRight({
        show: true, 
        control: true, 
        text: '...', //空字符串表示显示默认文本
        onSuccess: function(result) {
          dd.biz.util.share({
            type: 0, //分享类型，0:全部组件 默认； 1:只能分享到钉钉；2:不能分享，只有刷新按钮
            url: '',
            content: '狮课网（skcto.com）是由动力节点精心推出的IT在线教育平台，旨在通过提供最专业的IT技术精品好课和学习服务，有效帮助全网IT工程师实现职场提升和终身成长。',
            title: '狮课网·IT攻城狮的在线课堂！',
            image: '图片地址',
            onSuccess: function() {},
            onFail: function(err) {}
          })
        },
        onFail: function(err) {}
      });
    })
  }

}
