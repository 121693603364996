<template>
	<div class="head_content flexs">
		<div class="title_tishi flexs">
			<div class="flexs2 size12 text-grey">
				<div>动力节点旗下高端IT教育品牌</div>
				<div>全国咨询/投诉热线：400-8080-105</div>
			</div>
		</div>
		<div class="flexs2 cons">
			<div class="title flexs1">
				<img class="tc_img" src="../assets/image/logo.png" />
				<!-- <div class="tit_xian"></div> -->
				<!-- <img class="tit_img2" src="../assets/image/tit2.png" /> -->
				<img class="tit_img" src="../assets/image/tit1.png" />
				<!-- <div class="tit_img">
					<el-image style="width: 100%;height: 100%;" :src="require('@/assets/image/tit1.png')" fit="contain"></el-image>
				</div> -->
			</div>
			<div class="searchs flexs2">
				<input placeholder="请输入关键词" placeholder-class="ipt_tit" v-model="searchName" @keyup.enter.native="searchEnterFun" />
				<img src="../assets/image/search.png" @click="searchEnterFun" />
			</div>
		</div>
		<div class="cons1 flexs">
			<div class="flexs2 cons2">
				<div class="tac">
					<el-menu text-color="#333" active-color="#FE4400" router :default-active="statusName" mode="horizontal" :ellipsis="false" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose">
						<el-menu-item index="/" @click="toSelect(1, '/')">
							<span>首页</span>
							<div class="xian" :class="statusName == '/'?'active_xian':''"></div>
						</el-menu-item>
						<el-menu-item index="/liveDetails" @click="toSelect(1, '/liveDetails')">
							<span>热门直播</span>
							<div class="xian xian2" :class="statusName == '/liveDetails'?'active_xian':''"></div>
						</el-menu-item>
						<el-menu-item index="/goodsClass" @click="toSelect(1, '/goodsClass')">
							<span>精品好课</span>
							<div class="xian xian2" :class="statusName == '/goodsClass'?'active_xian':''"></div>
						</el-menu-item>
						<el-menu-item index="/interviews" @click="toSelect(1, '/interviews')">
							<span>面试宝典</span>
							<div class="xian xian2" :class="statusName == '/interviews'?'active_xian':''"></div>
						</el-menu-item>
						<el-menu-item index="/graduations" @click="toSelect(1, '/graduations')">
							<span>毕设参考</span>
							<div class="xian xian2" :class="statusName == '/graduations'?'active_xian':''"></div>
						</el-menu-item>
						<el-menu-item index="/circles" @click="toSelect(1, '/circles')">
							<span>
								攻城狮圈子
								<img src="../assets/image/qz.png" />
							</span>
							<div class="xian xian2" :class="statusName == '/circles'?'active_xian':''"></div>
						</el-menu-item>
					</el-menu>
				</div>
				<div class="flexs3 user_right">
					<img src="../assets/image/xx.png" @click="goMessage"/>
					<div class="pos" @click="goMessage">消息</div>
					<img class="left15" src="../assets/image/xz.png" @click="show3 = true"/>
					<div class="pos" @click="show3 = true">下载APP</div>
					<div class="btn" v-if="$store.state.user.msgCode == 4000" @click="showLogin">登录</div>
					<img class="tx_img" v-else :src="$store.state.user.userInfo.avatar" @click.stop="goUser" />
				</div>
				<div class="user_model" v-if="show1">
					<div class="flexs2 m_top">
						<div class="flexs1">
							<img class="head_img" :src="$store.state.user.userInfo.avatar" />
							<div class="name line1">{{$store.state.user.userInfo.nick_name}}</div>
						</div>
						<div class="text-gray" @click="goUsers">个人主页</div>
					</div>
					<div class="m_lists flexs2">
						<div class="list flexs1" @click="goCourse">
							<img src="../assets/publics/kc.png" />
							<div>我的课程</div>
						</div>
						<!-- <div class="list flexs1">
							<img src="../assets/publics/zl.png" />
							<div>我的资料</div>
						</div> -->
						<div class="list flexs1" @click="goMustTest()">
							<img src="../assets/publics/bst.png" />
							<div>必刷题</div>
						</div>
						<div class="list flexs1" @click="goTest()">
							<img src="../assets/publics/lxsj.png" />
							<div>练习试卷</div>
						</div>
					</div>
					<div class="m_bot flexs3">
						<div class="text-gray" @click.stop="outLogin">退出</div>
					</div>
				</div>
			</div>
		</div>
		<login-modal></login-modal>
		<new-exclusive></new-exclusive>
		<giftbag-data></giftbag-data>
		<el-dialog v-model="show2" width="400Px" :show-close="false" top="260Px" :close-on-click-modal="false">
			<div class="model2">
				<div class="m_cons flexs">
					<!-- <img src="../assets/image/tishi.png"/> -->
					<div class="size20">确定退出登录吗？</div>
				</div>
				<div class="m_btn flexs">
					<el-button type="primary" size="large" @click="sures">确定</el-button>
					&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<el-button type="info" size="large" @click="show2 = false">取消</el-button>
				</div>
			</div>
		</el-dialog>
		<el-dialog v-model="show3" width="360Px" top="200Px" :show-close="false" :close-on-click-modal="false">
			<div class="model">
				<div class="m_header flexs3">
					<img class="close_img" src="../assets/image/close.png" @click="show3 = false" />
				</div>
				<div class="m_cons">
					<img class="ewm_img" src="../assets/publics/xzapp.png"/>
					<div class="text-gray">
						扫描二维码
						下载狮课APP
					</div>
				</div>
				
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import loginModal from '@/components/loginModal'
	import newExclusive from '@/components/newExclusive'
	import giftbagData from '@/components/giftbagData'
	export default {
		components: {
			loginModal,
			newExclusive,
			giftbagData
		},
		name: 'NavHeader',
		props:{//接收父组件的数据
			headShow1:{type:Boolean,default:false}
		},
		data() {
			return {
				admin_name: localStorage.getItem('admin_name'),
				show1: false,
				activeName: 'second',
				status: 1,
				statusName : '/',
				leftList:[],
				show2: false,
				show3: false,
				searchName:'',
			}
		},
		watch:{
			headShow1(val){
				this.show1 = val
			}
		},
		created() {
			if (localStorage.getItem('admin_name')) {
				this.admin_name = localStorage.getItem('admin_name')
			} else {
				this.admin_name = "用户"
			}
			if(localStorage.getItem('status')){
				this.status = localStorage.getItem('status');
			}
			if(localStorage.getItem('statusName')){
				this.statusName = localStorage.getItem('statusName');
			}
			
			this.getUserData()
			
		},
		methods: {
			searchEnterFun(){
				this.show3 = false
				var params = {
					searchName: this.searchName
				}
				this.$router.push({
					path: 'searchPage',
					query: params
				})
			},
			
			getUserData() {
				this.$axios.get("/user/getUserInfo").then((res) => {
					this.$store.commit("SET_MSG_CODE",res.msgCode)
					if(res.msgCode == 0){
						this.$store.commit("SET_USER_INFO",res.result.data)
					}
				})
			},
			showLogin(){
				this.$store.commit("SET_LOGIN_SHOW",true)
				this.$store.commit("SET_CLOSE_STUS",false)
				// localStorage.setItem('login_show', this.login_show);
			},
			
			goCourse() {
				this.show1 = false
				this.$store.commit("SET_USER_SHOW","myClass")
				this.$router.push({
					path: 'user'
				})
			},
			/* goData() {
				this.show1 = false
				localStorage.setItem('showName', "meaasge");
				this.$router.push({
					path: 'user'
				})
			}, */
			goMustTest() {
				this.show1 = false
				this.$store.commit("SET_USER_SHOW","mustTest")
				this.$router.push({
					path: 'user'
				})
			},
			goTest() {
				this.show1 = false
				this.$store.commit("SET_USER_SHOW","myTest")
				this.$router.push({
					path: 'user'
				})
			},
			
			handleOpen(key, keyPath) {
				
				
			},
			handleClose(key, keyPath) {
				
			},
			toSelect(e,name){
				this.status = e
				this.statusName = name
				localStorage.setItem('status', this.status);
				localStorage.setItem('statusName', this.statusName);
			},
			sures() {
				localStorage.clear('admin_name')
				this.admin_name = '用户'
				this.show2 = false
				this.$router.push('/login')
			},
			handleClick(tab, event) {
				
			},
			outLogin(){
				localStorage.removeItem('token')
				this.$store.commit("SET_USER_INFO",{})
				this.$store.commit("SET_MSG_CODE",4000)
				this.$emit('changeHeadShow1', false)
			},
			goUser() {
				this.$emit('changeHeadShow1', true)
			},
			goUsers() {
				this.show1 = false
				this.$store.commit("SET_USER_SHOW","meaasge")
				this.$router.push({
					path: 'user'
				})
			},
			goMessage(){
				
				if (!localStorage.getItem('token') || this.$store.state.user.msgCode == 4000) {
					this.$store.commit("SET_LOGIN_SHOW", true)
					this.$store.commit("SET_CLOSE_STUS",false)
					return
				}
				this.$store.commit("SET_USER_SHOW","meaasge")
				this.$router.push({
					path: 'user'
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.head_content {
		background: #FFFFFF;
		height: 150Px;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
	}
	.title_tishi{
		height: 30Px;
		background: #F6F6F6;
		width: 100%;
		.flexs2{
			width: 1200Px;
		}
	}
	.cons {
		min-width: 1200Px;
		max-width: 1200Px;
		// margin-top: 17Px;
	}
	.cons1{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
	.cons2{
		min-width: 1200Px;
		max-width: 1200Px;
		position: relative;
		.user_model {
			position: absolute;
			top: 56Px;
			right: 0;
			width: 350Px;
			background: #FFFFFF;
			border-radius: 4Px;
			box-shadow: 0 0 5Px 2Px #F6F6F6;
			.m_top {
				padding: 25Px 25Px 20Px 25Px;
		
				.head_img {
					width: 45Px;
					height: 45Px;
					border-radius: 50%;
					margin-right: 10Px;
				}
		
				.name {
					font-size: 18Px;
					width: 170Px;
				}
		
				.text-gray {
					cursor: pointer;
				}
			}
		
			.m_lists {
				padding: 0 30Px;
				flex-wrap: wrap;
		
				.list {
					width: 130Px;
					height: 36Px;
					background: #F7F7F7;
					border-radius: 18Px;
					color: #444444;
					font-size: 14Px;
					margin-bottom: 20Px;
					cursor: pointer;
		
					img {
						width: 24Px;
						height: 24Px;
						margin: 0 3Px 0 19Px;
					}
				}
			}
		
			.m_bot {
				margin: 0 30Px;
				border-top: 1Px solid #EEEEEE;
				padding: 15Px 0;
		
				.text-gray {
					cursor: pointer;
				}
			}
		}
	}

	.title {
		font-size: 30Px;
		font-weight: bold;
	}

	.user_right {
		font-size: 16Px;
		color: #888888;
		padding-bottom: 10Px;
		img{
			width: 20Px;
			height: 20Px;
			margin-right: 3Px;
			cursor: pointer;
		}
		.pos {
			cursor: pointer;
		}
		
		.left15{
			margin-left:15Px;
		}
		.btn{
			color: #FFFFFF;
			width: 52Px;
			height: 24Px;
			border-radius: 12Px;
			background: #FE6600;
			line-height: 24Px;
			text-align: center;
			margin-left: 10Px;
			cursor: pointer;
		}
		.tx_img {
			width: 38Px;
			height: 38Px;
			border-radius: 50%;
			margin-left: 12Px;
			cursor: pointer;
		}
	}

	.tc_img {
		width: 160Px;
		height: 50Px;
	}
	.tit_xian{
		width: 1Px;
		height: 32Px;
		background: #CFCFCF;
		margin: 0 17Px 0 24Px;
	}
	.tit_img2 {
		width: 262Px;
		height: 22Px;
	}
	.tit_img {
		width: 290Px;
		height: 40Px;
		margin-left: 67Px;
	}

	.searchs {
		width: 420Px;
		height: 40Px;
		border: 1Px solid #DDDDDD;
		border-radius: 20Px;
		padding: 0 17Px;

		input {
			width: 210Px;
		}

		img {
			width: 24Px;
			height: 24Px;
			cursor: pointer;
		}
	}

	input {
		font-size: 14Px;
	}

	.model2 {
		.m_cons {

			// padding: 50Px 0;
			img {
				width: 40Px;
				height: 40Px;
				margin-right: 17Px;
			}

			font-weight: bold;
		}

		.m_btn {
			margin-top: 40Px;

			.el-button {
				width: 110Px;
				height: 40Px;
			}

			.el-button--info {
				background: #FFFFFF;
				border: 1Px solid #999999;
				color: #666666;
			}
		}
	}
	.model{
		background: #FFFFFF;
		border-radius: 10Px;
		margin: 0 auto;
		.m_header {
			width: 100%;
			height: 60Px;
			position: absolute;
			top: 0;
			left: 0;
			.close_img{
				width: 20Px;
				height: 20Px;
				margin-right: 20Px;
				cursor: pointer;
			}
		}
		.m_cons{
			text-align: center;
			.ewm_img{
				width: 212Px;
				height: 212Px;
				margin-bottom: 20Px;
			}
		}
		
	}
	.logo_img{
		width: 140Px;
		height: 140Px;
		margin: 50Px 0 25Px 50Px;
	}
	.is-active .el-sub-menu__title,.is-active{
		background: none!important;
		color: #FE4400!important;
	}
	.el-menu--horizontal>.el-menu-item.is-active{
		// border-bottom: none;
		color: #FE4400!important;
	}
	.icon{
		width: 32Px;
		height: 32Px;
		margin-right: 10Px;
	}
	
	.el-menu-item{
		height: 60Px;
		overflow: hidden;
		font-size: 16Px;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0!important;
		margin-right: 70Px;
	}
	.el-menu-item span{
		height: 20Px;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		margin-bottom: 12Px;
		img{
			width: 20Px;
			height: 20Px;
			line-height: 30Px;
			position: relative;
			bottom: 2Px;
		}
	}
	
	.is-opened{
		background: none;
	}
	
	.el-menu-item:hover{
		border-right: none;
		background: none!important;
	}
	
	.tac{
		height: 52Px;
		overflow: hidden;
	}
	.el-menu{
		height: 60Px;
		padding-left: 50Px;
	}
	.xian{
		width: 34Px;
		height: 3Px;
		background: #FFFFFF;
		position: relative;
		top: 5Px;
	}
	.xian2{
		width: 42Px;
	}
	.active_xian{
		background: #FE4400;
	}
	input::-webkit-input-placeholder { /* WebKit browsers */ 
	color:#BBBBBB; 
	} 
	
	input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */ 
	color:#BBBBBB; 
	} 
	
	input::-moz-placeholder { /* Mozilla Firefox 19+ */ 
	color:#BBBBBB;
	} 
	
	input:-ms-input-placeholder { /* Internet Explorer 10+ */ 
	color:#BBBBBB;
	} 
	

</style>
